<sfx-toolbar enableDivider>
    Applications & integrations
</sfx-toolbar>
<sfx-content>

    <sfx-header disableDividers>
        <span class="title">Applications</span>
    </sfx-header>
    <sfx-section disableContentPadding>
        <div data-flex data-layout="row wrap">
            <div class="card-container">
                <mat-card>
                    <mat-card-header>
                        <img class="avatar" src="images/applications/gmail.png" mat-card-avatar/>
                        <mat-card-title>Gmail</mat-card-title>
                        <mat-card-subtitle>Gmail sidebar for Chrome</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                            Work in Salesflare from your Gmail inbox.
                            Open an email and get the relevant customer info next to it.
                            Easily reply to an email from Salesflare. Track email opens and clicks.
                        </p>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button (click)="installChromePlugin()" [ngClass]="{ 'connected': chromePluginInstalled }" [disabled]="chromePluginInstalled">
                            <span>{{ chromePluginInstalled ? 'installed' : 'install' }}</span>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div class="card-container">
                <mat-card>
                    <mat-card-header>
                        <img class="avatar" src="images/applications/outlook.png" mat-card-avatar/>
                        <mat-card-title>Outlook</mat-card-title>
                        <mat-card-subtitle>Outlook sidebar for Windows, Mac and web</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                            Work in Salesflare from your Outlook inbox.
                            Open an email and get the relevant customer info next to it.
                            Easily reply to an email from Salesflare. Track email opens and clicks.
                        </p>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button (click)="showOutlookAddInGuide()">
                            <span>INSTALL</span>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div class="card-container">
                <mat-card>
                    <mat-card-header>
                        <img class="avatar" src="images/applications/app_store.png" mat-card-avatar/>
                        <mat-card-title>App Store</mat-card-title>
                        <mat-card-subtitle>iOS app for iPhone and iPad</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                            Always be connected with your customers. Access Salesflare on the go and get instant notifications.
                            The iOS app also automatically logs a call when you click on a telephone number.
                        </p>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button (click)="go('https://apps.apple.com/app/apple-store/id1061142976')">
                            <span>Download</span>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div class="card-container">
                <mat-card>
                    <mat-card-header>
                        <img class="avatar" src="images/applications/play_store.png" mat-card-avatar/>
                        <mat-card-title>Play Store</mat-card-title>
                        <mat-card-subtitle>Android app for phone and tablet</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                            Always be connected with your customers. Access Salesflare on the go and get instant notifications.
                            The Android app automatically logs all calls with customers by connecting with your phone's call log.
                        </p>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button (click)="go('https://play.google.com/store/apps/details?id=org.salesflare.app&referrer=utm_source%3Dsalesflare%26utm_campaign%3Dsettings')">
                            <span>Download</span>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div class="card-container">
                <mat-card>
                    <mat-card-header>
                        <img class="avatar" src="images/applications/linkedin.png" mat-card-avatar/>
                        <mat-card-title>LinkedIn</mat-card-title>
                        <mat-card-subtitle>LinkedIn sidebar for Chrome</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                            Work in Salesflare from LinkedIn & LinkedIn Sales Navigator.
                            Quickly create accounts and contacts from LinkedIn profiles and messaging inbox.
                            Find email addresses and add info automatically.
                        </p>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button (click)="installChromePlugin()" [ngClass]="{ 'connected': chromePluginInstalled }" [disabled]="chromePluginInstalled">
                            <span>{{ chromePluginInstalled ? 'installed' : 'install' }}</span>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </sfx-section>
    <sfx-header disableDividers>
        <span class="title">Integrations</span>
    </sfx-header>
    <sfx-section disableContentPadding>
        <div data-flex data-layout="row wrap">
            <div class="card-container">
                <mat-card>
                    <mat-card-header>
                        <img class="avatar" src="images/sf-icon.png" mat-card-avatar/>
                        <mat-card-title>Salesflare</mat-card-title>
                        <mat-card-subtitle>Explore Salesflare integrations</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                            Discover all native integrations between Salesflare and other applications as well as the most popular integrations through Zapier and Make.
                            Browse integrations by category and find out which ones are recommended
                        </p>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button (click)="go('https://integrations.salesflare.com')">
                            <span>Show integrations</span>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div class="card-container">
                <mat-card>
                    <mat-card-header>
                        <img class="avatar" src="images/applications/zapier.png" mat-card-avatar/>
                        <mat-card-title>Zapier</mat-card-title>
                        <mat-card-subtitle>Connect thousands of apps via Zapier</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                            Connect Salesflare with thousands of other apps like Google Contacts, MailChimp or your favorite invoicing software.
                            Using the button below you can get access to Salesflare's Zapier triggers and actions.
                        </p>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button (click)="go('https://zapier.com/apps/salesflare/integrations')">
                            <span>Get started</span>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div class="card-container">
                <mat-card>
                    <mat-card-header>
                        <img class="avatar" src="images/sf-icon.png" mat-card-avatar/>
                        <mat-card-title>Salesflare</mat-card-title>
                        <mat-card-subtitle>Website tracking script</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                            Know when visitors look at your website, which pages and for how long.
                            This script interacts with the email tracking to identify who of your customers goes to your website.
                            Just download the script and copy it at the end of the head tag, just before or after your Google Analytics script.
                        </p>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button (click)="showScript()">
                            <span>Show script</span>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div class="card-container">
                <mat-card>
                    <mat-card-header>
                        <img class="avatar" src="images/sf-icon.png" mat-card-avatar/>
                        <mat-card-title>Salesflare</mat-card-title>
                        <mat-card-subtitle>Email tracking links</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                            Track who clicks on your email (via MailChimp or similar) or social media campaigns.
                            Just envelop your hyperlinks with the Salesflare tracking to make it work.
                            Download the guide to learn exactly how.
                        </p>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button (click)="showGuide()">
                            <span>Show guide</span>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </sfx-section>
    <sfx-header disableDividers>
        <span class="title">Zapier templates</span>
    </sfx-header>
    <sfx-section disableContentPadding>
        <div class="zapier-app-directory-container" [id]="zapierAppDirectoryElementId">

        </div>
    </sfx-section>
</sfx-content>
